/** @jsx jsx */
import { jsx, Themed } from "theme-ui";
import React, { useEffect, useState } from "react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { navigate } from "gatsby";
import Layout from "components/Layout";
import {
  H1,
  MainSection,
  SecondarySection,
  SubHeader,
} from "components/AuthLayouts";
import Pill from "components/Pill";
import { b2cPolicies } from "../authConfig";

export default function MyProfile({
  pageContext: {
    profilePage: {
      title,
      description,
      brailleTextImage,
      fullNameLabel,
      editNameButtonLabel,
      emailInputLabel,
      editEmailButtonLabel,
      logoutButtonLabel,
      changePasswordTitle,
      changePasswordButtonLabel,
    },
  },
}) {
  const isAuthenticated = useIsAuthenticated();
  const { accounts, instance, inProgress } = useMsal();
  const [state, setState] = useState({ fullName: "", email: "" });

  useEffect(() => {
    if (!isAuthenticated && inProgress === InteractionStatus.None) {
      navigate("/", { replace: true });
    }
  }, [isAuthenticated, inProgress]);

  useEffect(() => {
    if (accounts.length) {
      const { name, username } = accounts[0];
      setState({ fullName: name, email: username });
    }
  }, [accounts]);

  async function editDisplayName() {
    try {
      const editProfileRequest = b2cPolicies.authorities.editProfile;
      editProfileRequest.loginHint = instance.getAccountByHomeId(
        accounts[0].homeAccountId
      ).username;
      instance.loginRedirect(editProfileRequest);
    } catch (e) {
      console.error(e);
    }
  }

  async function resetPassword() {
    try {
      const editProfileRequest = b2cPolicies.authorities.forgotPassword;
      editProfileRequest.loginHint = instance.getAccountByHomeId(
        accounts[0].homeAccountId
      ).username;
      instance.loginRedirect(editProfileRequest);
    } catch (e) {
      console.error(e);
    }
  }

  async function handleLogout() {
    try {
      const account = instance.getAccountByHomeId(accounts[0].homeAccountId);
      await instance.logoutRedirect({ account });
      // navigate("/", { replace: true });
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <Layout
      title={title}
      brailleTextImage={brailleTextImage}
      breadcrumbsMap={{ "create-new-profile": title }}
    >
      <MainSection
        style={{
          width: [null, null, "60%"],
          marginBottom: 32,
          position: "relative",
        }}
      >
        <H1>{title}</H1>
        <SubHeader>{description}</SubHeader>
        <Pill
          label={logoutButtonLabel}
          onClick={handleLogout}
          type="button"
          style={{
            position: [null, null, "absolute"],
            top: [null, null, 0],
            right: [null, null, 0],
            marginBottom: 32,
          }}
        />
        <div
          sx={{
            padding: ["24px 0", null, "32px 0"],
            borderTop: "2px solid var(--theme-ui-colors-lbbBlack)",
            borderBottom: "2px solid var(--theme-ui-colors-lbbBlack)",
          }}
        >
          <div
            sx={{
              display: [null, null, "flex"],
              justifyContent: "space-between",
            }}
          >
            <div>
              <Themed.p
                sx={{
                  fontSize: 0,
                  lineHeight: 0,
                  fontWeight: 500,
                  margin: 0,
                  marginBottom: 2,
                }}
              >
                {fullNameLabel}:
              </Themed.p>
              <Themed.p sx={{ margin: 0, padding: "14px 0" }}>
                {state.fullName}
              </Themed.p>
            </div>
            <Pill
              label={editNameButtonLabel}
              onClick={editDisplayName}
              style={{ alignSelf: "flex-end", marginTop: [16, 0] }}
            />
          </div>
        </div>
        <div
          sx={{
            padding: ["24px 0", null, "32px 0"],
            borderBottom: "2px solid var(--theme-ui-colors-lbbBlack)",
          }}
        >
          <div
            sx={{
              display: [null, null, "flex"],
              justifyContent: "space-between",
            }}
          >
            <div>
              <Themed.p
                sx={{
                  fontSize: 0,
                  lineHeight: 0,
                  fontWeight: 500,
                  margin: 0,
                  marginBottom: 2,
                }}
              >
                {emailInputLabel}:
              </Themed.p>
              <Themed.p sx={{ margin: 0, padding: "14px 0" }}>
                {state.email}
              </Themed.p>
            </div>

            <Pill
              label={editEmailButtonLabel}
              onClick={resetPassword}
              style={{ alignSelf: "flex-end", marginTop: [16, 0] }}
            />
          </div>
        </div>
      </MainSection>
      <SecondarySection style={{ width: [null, null, "60%"] }}>
        <Themed.h4 sx={{ marginBottom: ["24px", null, 0] }}>
          {changePasswordTitle}
        </Themed.h4>
        <Pill onClick={resetPassword} label={changePasswordButtonLabel} />
      </SecondarySection>
    </Layout>
  );
}
